import React, { useState } from 'react';
import axios from 'axios';
import moment from 'moment'

const App = () => {
  const [form, setForm] = useState({
    ip: '',
    port: '',
    time_interval: 1000
  });
  const [intervalFunc, setIntervalFunc] = useState();

  const randomAnntenas = () => {
    return Math.floor(Math.random() * 8) + 1;
  }

  const randomHostname = () => {
    const hostNames = ["FX9600JDISAD", "FX960010OKIF", "FX9600PLAAQW", "FX9600OI1003", "FX960012PKAB", "FX9600001100"]
    const randomIndex = Math.floor(Math.random() * hostNames.length)
    return hostNames[randomIndex]
  }

  function getRandomEpc() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 24; i++) {
      result += characters.charAt(Math.floor(Math.random() * 24));
    }
    return result;
  }


  const sendData = async () => {
    try {
      const data = `${randomAnntenas()},${randomHostname()},${getRandomEpc()},-57,1,,${new Date()}`
      await axios.post(`${process.env.REACT_APP_SERVER_PROXY}/send-data`, {
        data,
        port: form.port,
        ip: form.ip
      });
      const cmdDom = document.getElementById("data-cmd");
      const dataSentDiv = document.createElement("div");
      dataSentDiv.textContent = `${moment().format("DD/MM/YYYY HH:mm:ss")} :> ${data}`;
      cmdDom.appendChild(dataSentDiv)
    } catch (error) {
      console.error('Error sending data:', error);
    }
  };

  const connectToTcpServer = () => {
    if (!form.ip.trim() || !form.port.trim()) {
      alert("Please enter full IP and port information")
    } else {
      clearInterval(intervalFunc)
      const interval = setInterval(async () => {
        await sendData()
      }, form.time_interval >= 300 ? form.time_interval : 300)
      setIntervalFunc(interval)
    }
  }

  const renderForm = () => {
    return (
      <div style={{ padding: "0 10px", marginBottom: 10 }}>
        <div style={{ marginTop: 5 }}>
          <div>IP </div>
          <input placeholder='Enter IP' style={{ width: '100%' }} onChange={(e) => {
            setForm({ ...form, ip: e.target.value.trim() })
          }} />
        </div>
        <div style={{ marginTop: 5 }}>
          <div style={{ flex: 1 }}>Port </div>
          <input placeholder='Enter port' type='number' style={{ width: '100%' }} onChange={(e) => {
            setForm({ ...form, port: e.target.value.trim() })
          }} />
        </div>
        <div style={{ marginTop: 5 }}>
          <div style={{ flex: 1 }}>Message time interval (ms) </div>
          <input placeholder='Enter message time interval' type='number' style={{ width: '100%' }} onChange={(e) => {
            setForm({ ...form, time_interval: e.target.value.trim() })
          }} />
          <small>Min: 300ms</small>
        </div>
        <div style={{ textAlign: 'center', marginTop: 10 }}>
          <button onClick={connectToTcpServer}>Send data</button>
        </div>
      </div>
    )
  }

  const renderData = () => {
    return (
      <div style={{ minHeight: "100%", backgroundColor: 'black', color: "#fff" }}>
        <div style={{ padding: 5 }}>
          <div style={{ fontSize: "1.5rem" }}>
            <strong>
              RACETIME
            </strong>
          </div>
          <div>
            Data is transmitted using the TCP protocol.
          </div>
          <div>Enter the TCP server information to send sample data.</div>
          <div id="data-cmd">
          </div>
        </div>
      </div>
    )
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <div>
        <h1 style={{ textAlign: 'center' }}>RACETIME DATA</h1>
        {renderForm()}
      </div>
      <div style={{ flex: 1 }}>
        {renderData()}
      </div>
    </div>
  );
};

export default App;
